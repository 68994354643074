import './App.css';
import Crud from "./Crud"
import { Routes, Route, useNavigate } from "react-router-dom";


function App() {
    return (
    <div >
      < Crud/>
    </div>
  );
}

export default App;
